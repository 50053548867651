import { either } from "fp-ts/Either";
import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import {
  DisbursementStatus,
  RecurringStatus,
  FollowRequestStatus,
  CauseCategory,
  DonationFrequency,
  PaymentSourceType,
  FeedItemRecommendationReason,
  FeedItemType,
  NonprofitEditStatus,
  NonprofitTagNonprofitsNonprofitEditOperation,
  AdminLevel,
  VerifyEmailStatus,
  FundCreatingEntityType,
  UserFacingDonationChargeStatus,
  NonprofitAdminStatus,
  NonprofitMembershipStatus,
  GivingCreditType,
  PaymentMethod,
  PaymentSourceStatus,
  NonprofitDisplayType,
  NonprofitType,
  MediaType,
  DeactivateReason,
  AccountStatus,
  VerifiedStatus,
  ConfirmEmailChangeStatus,
  FundraiserGoalType,
  NonprofitAdminDonationNotificationSetting,
  DonationVisibility,
  CrmState,
  Crm,
  disbursementTypeUserFacingCodec,
  FundraiserType,
} from "../entity/types";
import { notificationDataCodec } from "../entity/types/notifications";

import { identityProviderCodec } from "./auth";
import { currencyValueCodec, currencyCodec } from "./currency";
import { dateFromStringCodec } from "./date";
import {
  descriptionLongCodec,
  descriptionCodec,
  communityFundraisingGoalSpec,
  donationThankYouMessageCodec,
  focusAreaCodec,
  slugCodec,
} from "./nonprofit";
import {
  nonNegativeIntegerFromStringCodec,
  integerFromStringCodec,
  safeIntCodec,
} from "./number";
import { commentTextCodec } from "./text";
import { usernameCodec } from "./username";

import { stringEnumCodec } from ".";

export type Uuid = t.TypeOf<typeof uuidCodec>;

export enum EntityName {
  API_PUBLIC_KEY = "ApiPublicKey",
  API_PRIVATE_KEY = "ApiPrivateKey",
  NONPROFIT = "Nonprofit",
  NONPROFIT_EDIT = "Nonprofit Edit",
  NONPROFIT_ADMIN = "Nonprofit Admin",
  NONPROFIT_ADMIN_INVITE = "Nonprofit Admin Invite",
  NONPROFIT_ADMIN_NOTIFICATION_SETTINGS = "Nonprofit Admin Notification Settings",
  USER = "User",
  USER_NONPROFIT_TAG = "User Nonprofit Tag",
  USER_PERSONAL = "User Personal",
  USER_PERSONAL_GUEST = "User Personal Guest",
  DONATION_CHARGE = "Donation Charge",
  DONATION_BOOST = "Donation Boost",
  PERSONAL_DONATION_CHARGE = "Personal Donation Charge",
  FEED_ITEM = "Feed Item",
  DONATION = "Donation",
  PERSONAL_DONATION = "Personal Donation",
  USER_FOLLOW = "User Follow",
  FOLLOW_INFO = "Follow Info",
  NOTIFICATION_PREFERENCES = "Notification Preferences",
  NOTIFICATION = "Notification",
  PAYMENT_SOURCE = "Payment Source",
  PAYPAL_PAYMENT_SOURCE = "Paypal Payment Source",
  SUPPORTER_INFO = "Donor Info",
  GIVING_CREDIT = "Giving Credit",
  PERSONAL_GIVING_CREDIT = "Personal Giving Credit",
  SIGNUP_INCENTIVE_CAMPAIGN = "Signup Incentive Campaign",
  SIGNUP_INCENTIVE = "Signup Incentive",
  FUNDRAISER = "Fundraiser",
  GIFT_CARD_CAMPAIGN = "Gift Card Campaign",
  TAG = "Tag",
  MEMBERSHIP = "Membership",
}

export interface ResponseEntity<Name extends EntityName> {
  objectType: Name;
}

export function createEntityResponseCodec<
  Name extends EntityName,
  Fields extends t.Mixed
>(params: { entityName: Name; fields: Fields }) {
  return t.intersection([
    t.type({
      entityName: t.literal(params.entityName),
    }),
    params.fields,
  ]);
}

export const donationFrequencyCodec = stringEnumCodec({
  name: "DonationFrequency",
  enumObject: DonationFrequency,
});

export const paymentMethodCodec = stringEnumCodec({
  name: "PaymentMethod",
  enumObject: PaymentMethod,
});

export const paymentSourceTypeCodec = stringEnumCodec({
  name: "PaymentSourceType",
  enumObject: PaymentSourceType,
});

export const deactivateReasonCodec = stringEnumCodec({
  name: "DeactivateReason",
  enumObject: DeactivateReason,
});

export const geoPointCodec = t.type({
  type: t.string,
  coordinates: t.array(t.number),
});

/**
 * Sets the limit of payment methods to add at a reasonable max since we don't
 * have pagination for payment method selections.
 */
export const MAX_NUM_PAYMENT_METHODS = 10;

export const causeCategoryCodec = stringEnumCodec({
  name: "CauseCategory",
  enumObject: CauseCategory,
});

export const userTagResponseCodec = createEntityResponseCodec({
  entityName: EntityName.USER_NONPROFIT_TAG,
  fields: t.type({
    nonprofitTagId: uuidCodec,
    likeCount: t.number,
    giftCount: t.number,
  }),
});

export type UserTagResponse = t.TypeOf<typeof userTagResponseCodec>;

export const membershipResponseCodec = createEntityResponseCodec({
  entityName: EntityName.MEMBERSHIP,
  fields: t.type({
    createdAt: dateFromStringCodec,
    status: stringEnumCodec({
      name: "NonprofitMembershipStatus",
      enumObject: NonprofitMembershipStatus,
    }),
    nonprofitId: uuidCodec,
  }),
});
export type MembershipResponse = t.TypeOf<typeof membershipResponseCodec>;

/**
 * io-ts codec that represents an incoming follow request status, meaning that
 * it's the status for another user following the current user.
 */
export const followingCurrentUserRequestStatusCodec = stringEnumCodec({
  name: "IncomingFollowRequestStatus",
  enumObject: FollowRequestStatus,
});

/**
 * io-ts codec that represents an outgoing follow request status, meaning that
 * it's the status for the current user following another user. Used to filter
 * states that the client for the current user should not be aware of.
 */
export const followedByCurrentUserRequestStatusCodec = new t.Type<
  FollowRequestStatus,
  string,
  unknown
>(
  "OutgoingFollowRequestStatus",
  (unknownValue): unknownValue is FollowRequestStatus =>
    typeof unknownValue === "string" &&
    FollowRequestStatus[unknownValue] !== undefined,
  (unknownValue, context) =>
    either.chain(t.string.validate(unknownValue, context), (stringValue) => {
      const enumEntry = Object.entries(FollowRequestStatus).find(
        (entry) => entry[1] === stringValue
      );
      return enumEntry
        ? t.success(FollowRequestStatus[enumEntry[0]])
        : t.failure(unknownValue, context);
    }),
  (status) =>
    // The client does not need to know about a REJECTED request. We treat these
    // requests as though there was no follow request and allow them to make
    // another request.
    status === FollowRequestStatus.REJECTED ? FollowRequestStatus.NONE : status
);

const userResponseFields = t.intersection([
  t.type({
    id: uuidCodec,
    isPrivate: t.boolean,
    verifiedStatus: stringEnumCodec({
      name: "VerifiedStatus",
      enumObject: VerifiedStatus,
    }),
    followedByCurrentUserStatus: followedByCurrentUserRequestStatusCodec,
  }),
  t.partial({
    firstName: t.union([t.string, t.null]),
    lastName: t.union([t.string, t.null]),
    legalName: t.union([t.string, t.null]),
    email: t.union([t.string, t.null]),
    username: usernameCodec,
    bioText: t.string,
    profileImageCloudinaryId: t.string,
    locationAddress: t.union([t.string, t.null]),
    followingCurrentUserStatus: followingCurrentUserRequestStatusCodec,
    inviter: uuidCodec || undefined,
    twitterHandle: t.union([t.string, t.null]),
    facebookHandle: t.union([t.string, t.null]),
    instagramHandle: t.union([t.string, t.null]),
    linkedInHandle: t.union([t.string, t.null]),
    blueskyHandle: t.union([t.string, t.null]),
    publicDonationAmount: t.boolean,
    youtubeHandle: t.union([t.string, t.null]),
    // TODO: remove after the next deploy
    youtubeUrl: t.union([t.string, t.null]),
    tagMeOnSocial: t.boolean,
    nonprofitTagLikes: t.union([t.array(uuidCodec), t.null]),
    isGuestWithExistingEmail: t.union([t.boolean, t.undefined]),
    identityProvider: identityProviderCodec,
  }),
]);

/**
 * User response. Doesn't allow for private information to be returned to client.
 *
 * Also flattens the user object for convenience's sake.
 */
export const userResponseCodec = createEntityResponseCodec({
  entityName: EntityName.USER,
  fields: userResponseFields,
});

export type UserResponse = t.TypeOf<typeof userResponseCodec>;

export const signupIncentiveCampaignResponseCodec = createEntityResponseCodec({
  entityName: EntityName.SIGNUP_INCENTIVE_CAMPAIGN,
  fields: t.type({
    id: uuidCodec,
    incentiveCurrency: currencyCodec,
    incentiveAmount: integerFromStringCodec,
    code: t.string,
  }),
});

export type SignupIncentiveCampaign = t.TypeOf<
  typeof signupIncentiveCampaignResponseCodec
>;

export const signupIncentiveResponseCodec = createEntityResponseCodec({
  entityName: EntityName.SIGNUP_INCENTIVE,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      newUserId: uuidCodec,
      campaign: signupIncentiveCampaignResponseCodec,
      redeemed: t.boolean,
      signedUpAt: dateFromStringCodec,
    }),
    t.partial({
      fromUserId: uuidCodec,
    }),
  ]),
});
export type SignupIncentive = t.TypeOf<typeof signupIncentiveResponseCodec>;

/**
 * io-ts codec that represents the admin level of the logged in user.
 */
export const adminLevelCodec = stringEnumCodec({
  name: "AdminLevel",
  enumObject: AdminLevel,
});

export const coverAssetCodec = t.union([
  t.type({
    mediaType: t.literal(MediaType.IMAGE),
    cloudinaryId: t.string,
  }),
  t.type({
    mediaType: t.literal(MediaType.VIDEO),
    videoUrl: t.string,
  }),
]);
export type CoverAsset = t.TypeOf<typeof coverAssetCodec>;

export const donationCampaignDataCodec = t.partial({
  campaignCoverAsset: coverAssetCodec,
  campaignTitle: t.string,
  // Total goal in min denomination (cents).
  campaignGoalAmount: integerFromStringCodec,
  // Starting amount to pad the thermometer value in min denomination.
  campaignBaselineAmount: integerFromStringCodec,
  campaignNoInvite: t.boolean,
  campaignCta: t.string,
});

export const recurringStatusCodec = stringEnumCodec({
  name: "RecurringStatus",
  enumObject: RecurringStatus,
});

const likesInfoCodec = t.type({
  count: t.number,
  hasLoggedInUserLiked: t.boolean,
});

const donationVisibilityCodec = stringEnumCodec({
  name: "DonationVisibility",
  enumObject: DonationVisibility,
});

/**
 * io-ts codec for donations to be displayed to your own profile.
 * Will contain values.
 */
export const personalDonationResponseCodec = createEntityResponseCodec({
  entityName: EntityName.PERSONAL_DONATION,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      toNonprofitId: uuidCodec,
      fromUserId: uuidCodec,
      commentText: t.union([commentTextCodec, t.null]),
      privateNote: t.union([commentTextCodec, t.null]),
      shortId: t.number,
      createdAt: dateFromStringCodec,
      nextScheduled: dateFromStringCodec,
      value: currencyValueCodec,
      tipAmount: currencyValueCodec,
      status: recurringStatusCodec,
      frequency: donationFrequencyCodec,
      likesInfo: likesInfoCodec,
      paymentMethod: paymentMethodCodec,
      externalPaymentSourceId: t.union([t.string, t.null]),
      shareInfo: t.boolean,
      isGuestDonation: t.union([t.boolean, t.undefined]),
      visibility: donationVisibilityCodec,
      fromFundraiserId: t.union([t.string, t.null]),
    }),
    t.partial({
      donationCampaignData: donationCampaignDataCodec,
      toNonprofitName: t.string,
      matchAmount: t.union([integerFromStringCodec, t.null]),
      metadata: t.partial({
        payToNonprofits: t.array(t.string),
        payToNonprofitWeights: t.array(t.number),
      }),
    }),
  ]),
});

export type PersonalDonationResponse = t.TypeOf<
  typeof personalDonationResponseCodec
>;

/**
 * A refinement type for ABTestingID that doesn't do anything, just forces us to
 * use AB testing IDs properly in our codebase
 */
export interface ABTestingBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly ABTestingId: unique symbol;
}

/**
 * Alias of string codec that forces a new type for AB testing IDs, to force
 * ourselves to only pass actual AB testing IDs to the AB test selection
 * functions, not loose strings or user IDs
 */
export const abTestingIdCodec = t.brand(
  t.string,
  (s): s is t.Branded<typeof s, ABTestingBrand> => true,
  "ABTestingId"
);
export type ABTestingId = t.TypeOf<typeof abTestingIdCodec>;

/**
 * These are fields shared between the guest and real personal user response
 * types.
 */
const personalUserSharedFields = t.intersection([
  t.type({
    abTestingId: abTestingIdCodec,
    lastDonation: t.union([personalDonationResponseCodec, t.null]),
    paidDonationCount: t.number,
  }),
  t.partial({
    lastVisited: dateFromStringCodec,
    // Might differ from lastDonation.ShareInfo if the last donatino was to
    // every.org gift cards or to a nonprofit that makes sharing info
    // mandatory
    lastDonationShareInfo: t.boolean,
    lastDonationVisibility: donationVisibilityCodec,
  }),
]);

export const guestUserResponseCodec = createEntityResponseCodec({
  entityName: EntityName.USER_PERSONAL_GUEST,
  fields: t.intersection([
    userResponseFields,
    personalUserSharedFields,
    t.partial({
      email: t.union([t.string, t.null]),
    }),
  ]),
});
export type GuestUserResponse = t.TypeOf<typeof guestUserResponseCodec>;

export const supporterInfoCodec = t.type({
  // Total number of supporters of this nonprofit, including people you know.
  numSupporters: t.number,
  // Whether the logged in user has supported this nonprofit before.
  loggedInUserSupported: t.boolean,
});

export const giftCardCampaignCodec = createEntityResponseCodec({
  entityName: EntityName.GIFT_CARD_CAMPAIGN,
  fields: t.type({
    id: uuidCodec,
    createdAt: dateFromStringCodec,
    nonprofitId: t.union([uuidCodec, t.undefined]),
    giftCardCurrency: currencyCodec,
    giftCardAmount: safeIntCodec,
    maxRedeemable: t.number,
    maxRedeemableByUser: t.union([t.number, t.null]),
    redeemed: t.number,
    expires: t.union([t.null, dateFromStringCodec]),
    giftCardExpirationDays: t.union([t.null, t.number]),
    canUserClaim: t.boolean,
  }),
});
export type GiftCardCampaignResponse = t.TypeOf<typeof giftCardCampaignCodec>;

/**
 * The campaign's type which determines eligibility conditions.
 */
export enum DonationMatchCampaignType {
  ONE_MATCH_PER_USER = "ONE_MATCH_PER_USER",
  ONE_MATCH_PER_NONPROFIT = "ONE_MATCH_PER_NONPROFIT",
  MULTIPLE_RECURRING = "MULTIPLE_RECURRING",
  UNLIMITED_MATCHES = "UNLIMITED_MATCHES",
}

export const nonEmptyMatchingCampaignBaseResponseCodec = t.type({
  id: uuidCodec,
  title: t.string,
  code: t.string,
  description: t.union([t.string, t.null]),
  startDate: t.union([dateFromStringCodec, t.null]),
  endDate: t.union([dateFromStringCodec, t.null]),
  active: t.boolean,
  deleted: t.boolean,
  currency: currencyCodec,
  maxPerUserMatchAmount: integerFromStringCodec,
  maxTotalMatchAmount: integerFromStringCodec,
  maxMatchPerDonationOneTimeAmount: t.union([
    nonNegativeIntegerFromStringCodec,
    t.null,
  ]),
  maxMatchPerDonationRecurringAmount: t.union([
    nonNegativeIntegerFromStringCodec,
    t.null,
  ]),
  matchMultiplierOneTime: t.number,
  matchMultiplierRecurring: t.number,
  matchGuests: t.boolean,
  matchShare: t.boolean,
  virtual: t.boolean,
  shareMatchAmount: t.union([integerFromStringCodec, t.null]),
  type: stringEnumCodec({
    name: "DonationMatchCampaignType",
    enumObject: DonationMatchCampaignType,
  }),
  maxAmountPerNonprofit: t.union([t.record(uuidCodec, safeIntCodec), t.null]),
});

export const nonEmptyMatchingCampaignResponseCodec = t.intersection([
  nonEmptyMatchingCampaignBaseResponseCodec,
  t.type({
    matchedTotal: integerFromStringCodec,
    matchedUser: t.union([integerFromStringCodec, t.null]),
    availableForMatching: integerFromStringCodec,
    availableAmountPerNonprofit: t.union([
      t.record(uuidCodec, safeIntCodec),
      t.null,
    ]),
  }),
]);

export type NonEmptyMatchingCampaignResponse = t.TypeOf<
  typeof nonEmptyMatchingCampaignResponseCodec
>;

export const matchingCampaignResponseCodec = t.union([
  t.type({}),
  nonEmptyMatchingCampaignResponseCodec,
]);

export type MatchingCampaignResponse = t.TypeOf<
  typeof matchingCampaignResponseCodec
>;

export const fundraiserRaisedResponseCodec = t.type({
  currency: currencyCodec,
  raised: integerFromStringCodec,
  raisedMonthly: integerFromStringCodec,
  raisedMatches: integerFromStringCodec,
  raisedOffline: integerFromStringCodec,
  goalAmount: integerFromStringCodec,
  goalType: stringEnumCodec({
    name: "FundraiserGoalType",
    enumObject: FundraiserGoalType,
  }),
  donations: safeIntCodec,
  supporters: safeIntCodec,
});
export type FundraiserRaisedResponse = t.TypeOf<
  typeof fundraiserRaisedResponseCodec
>;

export const fundraiserCodec = createEntityResponseCodec({
  entityName: EntityName.FUNDRAISER,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      createdAt: dateFromStringCodec,
      nonprofitId: uuidCodec,
      creatorUserId: t.union([uuidCodec, t.null]),
      creatorNonprofitId: t.union([uuidCodec, t.null]),
      type: stringEnumCodec({
        name: "FundraiserType",
        enumObject: FundraiserType,
      }),
      slug: t.string,
      title: t.string,
      description: t.union([t.string, t.null]),
      coverImageCloudinaryId: t.union([t.string, t.null]),
      active: t.boolean,
      startDate: t.union([dateFromStringCodec, t.null]),
      endDate: t.union([dateFromStringCodec, t.null]),
      pinnedAt: t.union([dateFromStringCodec, t.null]),
      goalCurrency: t.union([currencyCodec, t.null]),
      goalAmount: t.union([integerFromStringCodec, t.null]),
      metadata: t.union([
        t.partial({
          brandColor: t.string,
          orgName: t.string,
          avatarCloudinaryId: t.string,
          coverAssetOverride: coverAssetCodec,
          showInFeed: t.boolean,
          donationThankYouMessage: t.string,
          coverImageAltText: t.string,
          coverYoutubeVideoUrl: t.string,
          suggestedAmounts: t.array(t.number),
        }),
        t.null,
      ]),
      parentFundraiserId: t.union([uuidCodec, t.null]),
      childrenFundraiserIds: t.union([t.array(uuidCodec), t.null]),
      eventIds: t.union([t.array(uuidCodec), t.null]),
    }),
    t.partial({
      raisedData: fundraiserRaisedResponseCodec,
      hidden: t.boolean,
      forNonprofitIds: t.array(uuidCodec),
      matchCampaigns: t.array(nonEmptyMatchingCampaignBaseResponseCodec),
    }),
  ]),
});

export type FundraiserResponse = t.TypeOf<typeof fundraiserCodec>;

export const fundCreatingEntityTypeCodec = stringEnumCodec({
  name: "FundCreatingEntityType",
  enumObject: FundCreatingEntityType,
});

const nteeCodeMeaningCodec = t.intersection([
  t.partial({ majorCode: t.string, majorMeaning: t.string }),
  t.partial({ decileCode: t.string, decileMeaning: t.string }),
  t.partial({ centileCode: t.string, centileMeaning: t.string }),
]);
export type NteeCodeMeaningResponse = t.TypeOf<typeof nteeCodeMeaningCodec>;

/**
 * This is the set of metadata that we pass directly back to the client. The
 * full set of nonprofit potential metadata is much larger and can be seen in
 * the entity/Nonprofit file.
 */
export const nonprofitMetadataForDisplayCodec = t.partial({
  primaryBrandColor: t.string,
  community: t.partial({
    isPublic: t.boolean,
    fundraisingGoal: communityFundraisingGoalSpec,
    use2023Style: t.boolean,
    banner: t.partial({
      foregroundCloudinaryId: t.string,
      backgroundCloudinaryId: t.string,
    }),
    featured: t.array(
      t.partial({
        id: uuidCodec,
        ein: t.string,
        speaker: t.partial({
          name: t.string,
          title: t.string,
          imageUrl: t.string,
        }),
      })
    ),
  }),
  hidden: t.boolean,
  hideFromAPIs: t.boolean,
  focusArea: focusAreaCodec,
  announcement: t.string,
  customDonationAmounts: t.array(t.number),
  customMonthlyDescription: t.string,
  optInCheckbox: t.boolean,
  disablePrivateNotes: t.boolean,
  disablePublicTestimony: t.boolean,
  requireDonorInfo: t.boolean,
  disabledPaymentFlowOptions: t.array(t.string),
  minDonationValue: currencyValueCodec,
  privateNoteLimit: t.number,
  showStockPull: t.boolean, // Deprecated
  prefixWithThe: t.boolean,
  hideFundraiseButton: t.boolean,
  hideFundraiseButtonSuspectTraffic: t.boolean,
  logoAltText: t.string,
  coverImageAltText: t.string,
  enableSalesforce: t.boolean,
  hideDonationCount: t.boolean,
  disableTipping: t.boolean,
  hideDonateButtons: t.boolean,
  fiscalSponsorCustomDisclaimer: t.string,
  fiscalSponsorDisbursementCustomDisclaimer: t.string,
  fiscalSponsorDisbursementCustomContactEmail: t.string,
});
export type NonprofitMetadataForDisplay = t.TypeOf<
  typeof nonprofitMetadataForDisplayCodec
>;

export const nonprofitDataProps = {
  primarySlug: t.string, // TODO: modify after deploy as slugCodec. not typed as due to legacy slugs.
  ein: t.union([t.string, t.null]),
  donationsEnabled: t.boolean,
  name: t.string,
  description: t.union([t.string, t.null]), // TODO: Switch to descriptionCodec
  locationAddress: t.union([t.string, t.null]),
  descriptionLong: t.union([descriptionLongCodec, t.null]),
  websiteUrl: t.union([t.string, t.null]),
  twitterHandle: t.union([t.string, t.null]),
  blueskyHandle: t.union([t.string, t.null, t.undefined]),
  nteeCode: t.union([t.string, t.null]),
  causeCategory: causeCategoryCodec,
  logoCloudinaryId: t.union([t.string, t.null]),
  coverImageCloudinaryId: t.union([t.string, t.null]),
  facebookHandle: t.union([t.string, t.null]),
  revenueAmt: t.union([integerFromStringCodec, t.null]),
  type: stringEnumCodec({ name: "NonprofitType", enumObject: NonprofitType }),
  displayType: stringEnumCodec({
    name: "NonprofitDisplayType",
    enumObject: NonprofitDisplayType,
  }),
  fundCreatingEntityId: t.union([uuidCodec, t.null]),
  fundCreatingEntityType: t.union([fundCreatingEntityTypeCodec, t.null]),
  endorsedNonprofitIds: t.union([t.array(uuidCodec), t.null]),
  createdFundIds: t.union([t.array(uuidCodec), t.null]),
  endorserNonprofitIds: t.union([t.array(uuidCodec), t.null]),
  instagramHandle: t.union([t.string, t.null]),
  linkedInHandle: t.union([t.string, t.null]),
  countryCode: t.string,
  memberCount: t.union([t.number, t.null]),
  loggedInUserMembership: t.union([membershipResponseCodec, t.null]),
  donationThankYouMessage: t.union([donationThankYouMessageCodec, t.null]),
  archived: t.union([t.boolean, t.null]),
  holdDisbursements: t.boolean,
};

export const nonprofitResponseCodec = createEntityResponseCodec({
  entityName: EntityName.NONPROFIT,
  fields: t.intersection([
    t.type({ id: uuidCodec }),
    t.type(nonprofitDataProps),
    t.partial({
      createdAt: dateFromStringCodec,
      // Prerender was complaining about getting some undefined for locationLatLng, I don't if they do some cache stuff or what
      // Just moving it here from nonprofitDataProps just in case at least to silence those errors for now.
      locationLatLng: t.union([t.null, geoPointCodec]),
      supporterInfo: supporterInfoCodec,
      likesInfo: likesInfoCodec,
      nteeCodeMeaning: nteeCodeMeaningCodec,
      metadata: nonprofitMetadataForDisplayCodec,
      users: t.array(userResponseCodec),
      // TODO: move to nonprofitDataProps later, it's here for temporary compatibility
      youtubeHandle: t.union([t.string, t.null]),
      // TODO: remove after the next deploy
      youtubeUrl: t.union([t.string, t.null]),
      activeSignupCampaign: signupIncentiveCampaignResponseCodec,
      tags: t.array(uuidCodec),
      eligibleDonationRecipientNonprofitIds: t.array(uuidCodec),
      disbursementType: disbursementTypeUserFacingCodec,
      hasAdmin: t.union([t.boolean, t.undefined]),
      donatedCurrencies: t.union([t.array(currencyCodec), t.null]),
    }),
  ]),
});

export type NonprofitResponse = Omit<
  t.TypeOf<typeof nonprofitResponseCodec>,
  "causeCategory"
> & {
  /**
   * @deprecated
   */
  causeCategory: t.TypeOf<typeof causeCategoryCodec>;
};

export const tagDataPropsBase = {
  tagName: t.string,
  causeCategory: causeCategoryCodec,
  title: t.string,
  description: t.union([t.string, t.null]),
  shareText: t.union([t.string, t.null]),
  tagImageCloudinaryId: t.union([t.string, t.null]),
  shareImageCloudinaryId: t.union([t.string, t.null]),
  active: t.boolean,
  showInFilters: t.boolean,
  showInProfileEdit: t.boolean,
};

const tagDataProps = {
  id: uuidCodec,
  createdAt: dateFromStringCodec,
  ...tagDataPropsBase,
};

export const tagResponseCodec = createEntityResponseCodec({
  entityName: EntityName.TAG,
  fields: t.intersection([
    t.type(tagDataProps),
    t.partial({
      supporterInfo: t.type({
        numSupporters: t.number,
      }),
      likesCount: t.number,
      topNonprofits: t.array(t.string),
      totalDonated: currencyValueCodec,
      tagCoverImageCloudinaryId: t.union([t.string, t.null]),
      headerColor: t.union([t.string, t.null]),
    }),
  ]),
});

export type TagResponse = t.TypeOf<typeof tagResponseCodec>;

/**
 * io-ts codec for donations to be displayed to other profiles.
 * Will not contain values.
 */
export const donationResponseCodec = createEntityResponseCodec({
  entityName: EntityName.DONATION,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      toNonprofitId: uuidCodec,
      fromUserId: uuidCodec,
      commentText: t.union([commentTextCodec, t.null]),
      shortId: t.number,
      createdAt: dateFromStringCodec,
      status: recurringStatusCodec,
      frequency: donationFrequencyCodec,
      likesInfo: likesInfoCodec,
      timesCharged: t.number,
      fromFundraiserId: t.union([t.string, t.null]),
    }),
    t.partial({
      donationCampaignData: donationCampaignDataCodec,
    }),
  ]),
});

export type DonationResponse = t.TypeOf<typeof donationResponseCodec>;

const donationAmountsInfoCodec = t.intersection([
  t.type({
    value: t.type({
      currency: currencyCodec,
      amountInMinDenom: safeIntCodec,
    }),
  }),
  t.partial({
    quantity: t.number,
    symbol: t.string,
  }),
]);
export type DonationAmountsInfo = t.TypeOf<typeof donationAmountsInfoCodec>;
/**
 * io-ts codec for donation charges to be displayed to other profiles.
 * Will not contain values.
 */
export const donationChargeResponseCodec = createEntityResponseCodec({
  entityName: EntityName.DONATION_CHARGE,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      toNonprofitId: uuidCodec,
      fromUserId: uuidCodec,
      createdAt: dateFromStringCodec,
      donation: donationResponseCodec,
    }),
    t.partial({
      isPending: t.boolean,
      amounts: donationAmountsInfoCodec,
    }),
  ]),
});
export type DonationChargeResponse = t.TypeOf<
  typeof donationChargeResponseCodec
>;

const donationChargeStatusCodec = stringEnumCodec({
  name: "DonationChargeStatus",
  enumObject: UserFacingDonationChargeStatus,
});

/**
 * io-ts codec for donation charges to be displayed to clients. May not contain
 * the value if the user is not privileged to view it.
 */
export const personalDonationChargeResponseCodec = createEntityResponseCodec({
  entityName: EntityName.PERSONAL_DONATION_CHARGE,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      toNonprofitId: uuidCodec,
      fromUserId: uuidCodec,
      createdAt: dateFromStringCodec,
      donation: personalDonationResponseCodec,
      value: currencyValueCodec,
      creditAmount: integerFromStringCodec,
      paidAmount: integerFromStringCodec,
      tipAmount: integerFromStringCodec,
      status: donationChargeStatusCodec,
      chargeId: t.union([t.string, t.null]),
      externalPaymentSourceId: t.union([t.string, t.null]),
      paymentMethod: paymentMethodCodec,
    }),
    t.partial({
      amounts: t.intersection([
        t.type({
          value: t.type({
            currency: currencyCodec,
            amountInMinDenom: safeIntCodec,
          }),
        }),
        t.partial({
          quantity: t.number,
          symbol: t.string,
        }),
      ]),
      matchAmount: t.union([integerFromStringCodec, t.null]),
      metadata: t.partial({
        symbol: t.string,
        quantity: t.union([t.number, t.string]),
        usdValue: t.string,
        usdValueAtPledge: t.string,
        unitaryAvgPrice: t.number,
        dateReceived: dateFromStringCodec,
      }),
      disbursements: t.array(
        t.type({
          method: disbursementTypeUserFacingCodec,
          status: stringEnumCodec({
            name: "DisbursementStatus",
            enumObject: DisbursementStatus,
          }),
        })
      ),
      splitCharges: t.array(
        t.type({
          nonprofitName: t.string,
          finalAmount: safeIntCodec,
          matchAmount: safeIntCodec,
        })
      ),
    }),
  ]),
});
export type PersonalDonationChargeResponse = t.TypeOf<
  typeof personalDonationChargeResponseCodec
>;

export const donationBoostResponseCodec = createEntityResponseCodec({
  entityName: EntityName.DONATION_BOOST,
  fields: t.type({
    parentDonation: t.union([donationResponseCodec, t.null]),
    grandparentDonations: t.union([t.array(donationResponseCodec), t.null]),
    /**
     * Number of unique people who joined this donation.
     */
    numJoined: t.number,
  }),
});
export type DonationBoostResponse = t.TypeOf<typeof donationBoostResponseCodec>;

export const feedUserDonationCodec = t.type({
  type: t.literal(FeedItemType.USER_DONATION),
  donationCharge: donationChargeResponseCodec,
  boost: donationBoostResponseCodec,
});
export type FeedUserDonationResponse = t.TypeOf<typeof feedUserDonationCodec>;

export const feedNonprofitRecCodec = t.type({
  type: t.literal(FeedItemType.NONPROFIT_RECOMMENDATION),
  nonprofitId: uuidCodec,
});
export type FeedNonprofitRecResponse = t.TypeOf<typeof feedNonprofitRecCodec>;

export const createdFundCodec = t.type({
  type: t.literal(FeedItemType.CREATED_FUND),
  nonprofitId: t.string,
});
export type CreatedFundResponse = t.TypeOf<typeof createdFundCodec>;

const feedFundraiserCodec = t.type({
  type: t.literal(FeedItemType.FUNDRAISER),
  fundraiser: fundraiserCodec,
});

/**
 * io-ts codec for feed items to be displayed.
 */
export const feedItemResponseCodec = createEntityResponseCodec({
  entityName: EntityName.FEED_ITEM,
  fields: t.intersection([
    t.partial({
      feedId: uuidCodec,
      blendedScore: t.number,
      score: t.number,
      reason: t.union([
        t.literal(FeedItemRecommendationReason.FEED_MATCH),
        t.literal(FeedItemRecommendationReason.NONPROFIT_DESCRIPTION),
        t.literal(FeedItemRecommendationReason.NONPROFIT_SIMILAR_DONATIONS),
        t.literal(FeedItemRecommendationReason.NONPROFIT_SHARED_SUPPORTERS),
        t.literal(FeedItemRecommendationReason.USER_DONATIONS),
        t.literal(FeedItemRecommendationReason.NONPROFIT_ENDORSED),
      ]),
    }),
    t.union([
      feedUserDonationCodec,
      feedNonprofitRecCodec,
      createdFundCodec,
      feedFundraiserCodec,
    ]),
  ]),
});

/**
 * Shape of an item in the feed as returned by the API
 */
export type FeedItemResponse = t.TypeOf<typeof feedItemResponseCodec>;

/**
 * io-ts codec for user follow to be displayed to clients.
 *
 * TODO: make this configurable to allow for omitting fields based on privacy
 * settings, like value
 */
export const userFollowResponseCodec = createEntityResponseCodec({
  entityName: EntityName.USER_FOLLOW,
  fields: t.type({
    id: uuidCodec,
    fromUserId: uuidCodec,
    toUserId: uuidCodec,
    accepted: followingCurrentUserRequestStatusCodec,
  }),
});

/**
 * Codec for follow information for a user.
 */
export const followInfoResponseCodec = createEntityResponseCodec({
  entityName: EntityName.FOLLOW_INFO,
  fields: t.type({
    userId: uuidCodec,
    followerCount: t.number,
    followingCount: t.number,
  }),
});
export interface FollowInfoResponse
  extends t.TypeOf<typeof followInfoResponseCodec> {}

export const notificationResponseCodec = createEntityResponseCodec({
  entityName: EntityName.NOTIFICATION,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      createdAt: dateFromStringCodec,
      data: notificationDataCodec,
      wasCleared: t.boolean,
    }),
    t.partial({
      viewedInAppAt: t.union([dateFromStringCodec, t.null]),
      interactedWithInAppAt: t.union([dateFromStringCodec, t.null]),
    }),
  ]),
});

export type NotificationResponse = t.TypeOf<typeof notificationResponseCodec>;

/**
 * io-ts codec for a payment source, a single chargeable account such as a
 * credit card or bank account.
 */
const paypalPaymentSourceCodec = createEntityResponseCodec({
  entityName: EntityName.PAYPAL_PAYMENT_SOURCE,
  fields: t.intersection([
    t.type({
      paymentMethod: stringEnumCodec({
        enumObject: { [PaymentMethod.PAYPAL]: PaymentMethod.PAYPAL },
        name: "PaymentMethodPaypal",
      }),
      default: t.boolean,
    }),
    t.partial({
      type: t.null,
      id: t.null,
      externalId: t.null,
      brandName: t.string,
      displayId: t.null,
      status: t.null,
    }),
  ]),
});
const stripePaymentSourceCodec = createEntityResponseCodec({
  entityName: EntityName.PAYMENT_SOURCE,
  fields: t.type({
    paymentMethod: t.literal(PaymentMethod.STRIPE),
    default: t.boolean,
    type: stringEnumCodec({
      enumObject: PaymentSourceType,
      name: "StripeSourceType",
    }),
    id: t.string,
    externalId: t.string,
    brandName: t.union([t.string, t.null]),
    displayId: t.union([t.string, t.null]),
    status: stringEnumCodec({
      enumObject: PaymentSourceStatus,
      name: "PaymentSourceStatus",
    }),
  }),
});
export const paymentSourceResponseCodec = t.union([
  paypalPaymentSourceCodec,
  stripePaymentSourceCodec,
]);
export type StripePaymentSourceResponse = t.TypeOf<
  typeof stripePaymentSourceCodec
>;
export type PaymentSourceResponse = t.TypeOf<typeof paymentSourceResponseCodec>;

export const cloudinaryAuthSignatureResponseBodyCodec = t.type({
  signature: t.string,
  timestamp: t.string,
});

/**
 * io-ts codec that represents the status of an email verification request.
 */
export const verifyEmailStatusCodec = stringEnumCodec({
  name: "VerifyEmailStatus",
  enumObject: VerifyEmailStatus,
});

const baseGivingCreditFields = t.intersection([
  t.type({
    id: uuidCodec,
    createdAt: dateFromStringCodec,
    currency: currencyCodec,
    startingAmount: nonNegativeIntegerFromStringCodec,
    expires: t.union([dateFromStringCodec, t.null]),
    redemptionExpires: t.union([dateFromStringCodec, t.null]),
    givingCreditType: stringEnumCodec({
      enumObject: GivingCreditType,
      name: "GivingCreditType",
    }),
    token: t.union([t.string, t.null]),
  }),
  t.partial({
    fromUser: userResponseCodec,
  }),
  t.partial({
    fromNonprofit: nonprofitResponseCodec,
  }),
  t.partial({
    forCommunity: nonprofitResponseCodec,
  }),
  t.partial({
    restrictedToCauses: t.array(tagResponseCodec),
    restrictedToNonprofits: t.array(nonprofitResponseCodec),
  }),
]);

export const givingCreditResponseCodec = createEntityResponseCodec({
  entityName: EntityName.GIVING_CREDIT,
  fields: baseGivingCreditFields,
});
export type GivingCreditResponse = t.TypeOf<typeof givingCreditResponseCodec>;

export const personalGivingCreditResponseCodec = createEntityResponseCodec({
  entityName: EntityName.PERSONAL_GIVING_CREDIT,
  fields: t.intersection([
    baseGivingCreditFields,
    t.type({
      amountRemaining: integerFromStringCodec,
    }),
  ]),
});
export type PersonalGivingCreditResponse = t.TypeOf<
  typeof personalGivingCreditResponseCodec
>;

/**
 * io-ts codec that represents the status of a nonprofit edit request.
 */
export const nonprofitEditStatusCodec = stringEnumCodec({
  name: "NonprofitEditStatus",
  enumObject: NonprofitEditStatus,
});

export const appliedNonprofitEditResponseCodec = t.type({
  status: nonprofitEditStatusCodec,
  nonprofit: nonprofitResponseCodec,
});

/**
 * Some fields we do not allow modification of through the client-facing edit
 * tool (e.g. fajaLikes, revenueAmt). Cannot unset any value, can only add or
 * change data (undefined means unchanged)
 */
export const nonprofitEditDataCodec = t.partial({
  primarySlug: slugCodec,
  name: t.string,
  description: descriptionCodec,
  locationAddress: t.string,
  locationLatLng: t.union([t.null, geoPointCodec]),
  descriptionLong: descriptionLongCodec,
  websiteUrl: t.string,
  twitterHandle: t.string,
  causeCategory: causeCategoryCodec,
  logoCloudinaryId: t.string,
  coverImageCloudinaryId: t.string,
  facebookHandle: t.string,
  instagramHandle: t.string,
  linkedInHandle: t.string,
  youtubeHandle: t.string,
  blueskyHandle: t.string,
  // TODO: remove after the next deploy
  youtubeUrl: t.union([t.string, t.null]),
  donationThankYouMessage: donationThankYouMessageCodec,
  lat: t.string,
  lng: t.string,
  metadata: nonprofitMetadataForDisplayCodec,
});
export type NonprofitEditData = t.TypeOf<typeof nonprofitEditDataCodec>;
export const nonprofitEditResponseCodec = createEntityResponseCodec({
  entityName: EntityName.NONPROFIT_EDIT,
  fields: t.type({
    id: uuidCodec,
    createdAt: dateFromStringCodec,
    nonprofitId: uuidCodec,
    creatorUserId: uuidCodec,
    edit: nonprofitEditDataCodec,
  }),
});
export type NonprofitEdit = t.TypeOf<typeof nonprofitEditResponseCodec>;

export const nonprofitAdminNotificationSettingsConfigurationCodec = t.partial({
  /**
   * Controls whether or not to send emails about disbursements (via Stripe).
   */
  sendDisbursementEmails: t.boolean,
  /**
   * Controls whether or not to send emails about individual donations.
   */
  sendDonationNotificationEmails: stringEnumCodec({
    name: "nonprofitAdminDonationNotificationSetting",
    enumObject: NonprofitAdminDonationNotificationSetting,
  }),
  /**
   * Controls whether or not to send emails about new fundraisers that
   * have been created by someone who is not an admin of the nonprofit.
   */
  sendNewFundraiserNotificationEmails: t.boolean,
  /**
   * Controls whether or not to send any of the emails above to the
   * parent admin when a project has a notification
   */
  sendProjectEmails: t.boolean,
  /**
   * Controls whether or not to send emails when a recurring donation
   * that was being executed successfully fails for the first time and
   * the donor doesn't update the payment method and retries the charge.
   * Or when a donation gets cancelled or paused.
   */
  sendRecurringDonationStop: t.boolean,
});
export type NonprofitAdminNotificationSettingsConfiguration = t.TypeOf<
  typeof nonprofitAdminNotificationSettingsConfigurationCodec
>;

export const nonprofitAdminNotificationSettingsResponseCodec =
  createEntityResponseCodec({
    entityName: EntityName.NONPROFIT_ADMIN_NOTIFICATION_SETTINGS,
    fields: t.type({
      nonprofitId: uuidCodec,
      userId: uuidCodec,
      configuration: nonprofitAdminNotificationSettingsConfigurationCodec,
    }),
  });
export type NonprofitAdminNotificationSettingsResponse = t.TypeOf<
  typeof nonprofitAdminNotificationSettingsResponseCodec
>;

export const nonprofitAdminResponseCodec = createEntityResponseCodec({
  entityName: EntityName.NONPROFIT_ADMIN,
  fields: t.intersection([
    t.type({
      directAdmin: t.boolean,
      status: stringEnumCodec({
        name: "NonprofitAdminStatus",
        enumObject: NonprofitAdminStatus,
      }),
      nonprofit: nonprofitResponseCodec,
    }),
    t.partial({
      nonprofitTags: t.array(tagResponseCodec),
    }),
  ]),
});

export type NonprofitAdminResponse = t.TypeOf<
  typeof nonprofitAdminResponseCodec
>;

export const nonprofitAdminInviteResponseCodec = createEntityResponseCodec({
  entityName: EntityName.NONPROFIT_ADMIN_INVITE,
  fields: t.intersection([
    t.type({
      id: uuidCodec,
      accepted: t.boolean,
      email: t.string,
      expires: t.union([dateFromStringCodec, t.null]),
      nonprofitId: uuidCodec,
    }),
    t.partial({
      inviterId: uuidCodec,
      inviteeId: uuidCodec,
    }),
  ]),
});

export type NonprofitAdminInviteResponse = t.TypeOf<
  typeof nonprofitAdminInviteResponseCodec
>;

export const nonprofitAdminAndInvitesResponseCodec = t.type({
  admins: t.array(
    t.type({
      userId: uuidCodec,
      isParentAdmin: t.boolean,
      emailAddress: t.string,
    })
  ),
  adminInvites: t.array(nonprofitAdminInviteResponseCodec),
  users: t.array(userResponseCodec),
});

export type NonprofitAdminAndInvitesResponse = t.TypeOf<
  typeof nonprofitAdminAndInvitesResponseCodec
>;

/**
 * User response, including personal data.
 */
export const personalUserResponseCodec = createEntityResponseCodec({
  entityName: EntityName.USER_PERSONAL,
  fields: t.intersection([
    personalUserSharedFields,
    t.partial({
      email: t.union([t.string, t.null]),
      identityProvider: identityProviderCodec,
      facebookId: t.string,
      googleId: t.string,
      followedCauses: t.array(causeCategoryCodec),
      signupIncentive: signupIncentiveResponseCodec,
      adminFor: t.array(nonprofitAdminResponseCodec),
      memberships: t.array(membershipResponseCodec),
    }),
    t.type({
      // Admin level, used only to determine UI display logic. Don't use for security!
      adminLevelForDisplay: adminLevelCodec,
      isEmailVerified: t.boolean,
      accountStatus: stringEnumCodec({
        name: "AccountStatus",
        enumObject: AccountStatus,
      }),
      legalName: t.union([t.string, t.null]),
    }),
    userResponseFields,
  ]),
});
/**
 * Type of the current logged-in user.
 */
export type PersonalUserResponse = t.TypeOf<typeof personalUserResponseCodec>;

/**
 * io-ts codec for nonprofit ~ tag suggestions
 */
export const nonprofitTagEditResponseCodec = t.type({
  id: uuidCodec,
  nonprofitTagId: uuidCodec,
  nonprofitId: uuidCodec,
  creatorUserId: uuidCodec,
  operation: stringEnumCodec({
    name: "operation",
    enumObject: NonprofitTagNonprofitsNonprofitEditOperation,
  }),
});
export const nonprofitsTagsEditsResponseCodec = t.type({
  edits: t.array(nonprofitTagEditResponseCodec),
  tags: t.array(tagResponseCodec),
  nonprofits: t.array(nonprofitResponseCodec),
  users: t.array(userResponseCodec),
  hasMore: t.boolean,
});
export type NonprofitTagEditResponse = t.TypeOf<
  typeof nonprofitTagEditResponseCodec
>;

export const givingPledgeResponseCodec = t.type({
  userId: uuidCodec,
  pledgeAmount: integerFromStringCodec,
  pledgeCurrency: currencyCodec,
  startDate: dateFromStringCodec,
  endDate: dateFromStringCodec,
  progressAmount: t.number,
  active: t.boolean,
});
export type GivingPledgeResponse = t.TypeOf<typeof givingPledgeResponseCodec>;

export const achievementResponseCodec = t.type({
  key: t.string,
  title: t.string,
  description: t.string,
  enabled: t.boolean,
  badgeCloudinaryId: t.union([t.string, t.null]),
  // TODO: this should be a currency value with associated currency + safe int,
  // not just a bare number; first add an optional field that contains the
  // correctly formatted value, change API to provide it + frontend to use it,
  // deploy; then 2nd PR later on that removes this field, to avoid breaking old
  // clients
  incentiveRewardAmount: t.union([t.number, t.null]),
  maxRedeemable: t.number,
  invalidAfter: t.union([dateFromStringCodec, t.null]),
  numEarned: t.number,
  lastEarnedDate: t.union([dateFromStringCodec, t.null]),
});

export type AchievementResponse = t.TypeOf<typeof achievementResponseCodec>;

export const apiPublicKeyResponseCodec = createEntityResponseCodec({
  entityName: EntityName.API_PUBLIC_KEY,
  fields: t.type({
    createdAt: dateFromStringCodec,
    userId: t.union([uuidCodec, t.null]),
    key: t.string,
    label: t.union([t.string, t.null]),
  }),
});
export type ApiPublicKeyResponse = t.TypeOf<typeof apiPublicKeyResponseCodec>;

export const apiPrivateKeyResponseCodec = createEntityResponseCodec({
  entityName: EntityName.API_PRIVATE_KEY,
  fields: t.type({
    id: uuidCodec,
    createdAt: dateFromStringCodec,
    userId: t.union([uuidCodec, t.null]),
    label: t.union([t.string, t.null]),
  }),
});
export type ApiPrivateKeyResponse = t.TypeOf<typeof apiPrivateKeyResponseCodec>;

export const confirmEmailChangeStatusCodec = stringEnumCodec({
  name: "ConfirmEmailChangeStatus",
  enumObject: ConfirmEmailChangeStatus,
});

export const salesforceAccountMetadataCodec = t.partial({
  instanceUrl: t.string,
  state: stringEnumCodec({
    name: "crmState",
    enumObject: CrmState,
  }),
  errorMessage: t.string,
  lastSync: dateFromStringCodec,
  active: t.boolean,
  name: t.string,
  sandbox: t.boolean,
  authRequestToken: t.union([t.string, t.null]),
  authRequestTokenExpires: t.union([dateFromStringCodec, t.null]),
});

export type SalesforceAccountMetadata = t.TypeOf<
  typeof salesforceAccountMetadataCodec
>;

export const crmAccountMetadataCodec = salesforceAccountMetadataCodec;

export type CrmAccountMetadataCodec = t.TypeOf<typeof crmAccountMetadataCodec>;

export const crmAccountCodec = t.intersection([
  t.type({ metadata: crmAccountMetadataCodec }),
  t.type({ id: uuidCodec }),
]);

export type CrmAccount = t.TypeOf<typeof crmAccountCodec>;

export type CrmAccountMetadata<C extends Crm> = {
  [Crm.SALESFORCE]: SalesforceAccountMetadata;
}[C];
