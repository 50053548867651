import { IconProps } from "@components/Icon";

import { SocialHandleKey } from "@every.org/common/src/helpers/socialNetworks";

export const URL_KEYS = new Set(["websiteUrl"]);

export const socialLinksMeta: Array<{
  key: string;
  placeholder: string;
  iconImport: IconProps["iconImport"];
  popoverText?: string;
}> = [
  {
    key: SocialHandleKey.FACEBOOK,
    placeholder: "facebook.com/",
    iconImport: () => import("@components/Icon/icons/FacebookIcon"),
  },
  {
    key: SocialHandleKey.INSTAGRAM,
    placeholder: "instagram.com/",
    iconImport: () => import("@components/Icon/icons/InstagramLogoCircleIcon"),
  },
  {
    key: SocialHandleKey.LINKEDIN,
    placeholder: "linkedin.com/",
    iconImport: () => import("@components/Icon/icons/LinkedinLogoCircleIcon"),
    popoverText:
      "Make sure to include the full URL prefix, including in/, company/, school/, etc.",
  },
  {
    key: SocialHandleKey.TWITTER,
    placeholder: "x.com/",
    iconImport: () => import("@components/Icon/icons/XLogoCircleIcon"),
  },
  {
    key: SocialHandleKey.YOUTUBE,
    placeholder: "youtube.com/",
    iconImport: () => import("@components/Icon/icons/YoutubeLogoCircleIcon"),
    popoverText:
      "Make sure to include the full URL prefix, including c/, channel/, watch?v=, etc.",
  },
  {
    key: SocialHandleKey.BLUESKY,
    placeholder: "bsky.app/profile/",
    iconImport: () => import("@components/Icon/icons/BlueskyLogoCircleIcon"),
  },
];
