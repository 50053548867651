const SOCIAL_URL_PATTERN = new RegExp(
  /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:(?:twitter|x|instagram|facebook|linkedin|youtube)\.com)?\/?@?/,
  "i"
);

const AFTER_FIRST_SLASH_PATTERN = new RegExp(/\/.*/, "g");

// Regular expression to match YouTube video URL patterns
const YOUTUBE_URL_REGEX = new RegExp(
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
);

const YOUTUBE_PROFILE_PATTERN = new RegExp(
  /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:(?:youtube)\.com\/)/,
  "i"
);

const BLUESKY_PROFILE_PATTERN = new RegExp(
  /^(?:https?:\/\/)?(?:www\.)?(?:(?:bsky)\.app\/profile)?\/?@?/,
  "i"
);

const QUERY_PARAMS_PATTERN = new RegExp(/[?&]([^=&]+)(?:=[^&]*)?/g);

export enum SocialHandleKey {
  YOUTUBE = "youtubeHandle",
  FACEBOOK = "facebookHandle",
  INSTAGRAM = "instagramHandle",
  LINKEDIN = "linkedInHandle",
  TWITTER = "twitterHandle",
  BLUESKY = "blueskyHandle",
}

export function youtubeProfileHandle(handle: string | null | undefined) {
  return !handle || handle.startsWith("@") || handle.includes("/")
    ? handle
    : "@" + handle;
}

export function cleanSocialNetworkHandle(
  handle: string | null | undefined,
  key?: string | null | undefined
) {
  if (!handle) {
    return handle;
  }

  if (key === SocialHandleKey.YOUTUBE) {
    const cleanHandle = handle.replace(YOUTUBE_PROFILE_PATTERN, "");
    return youtubeProfileHandle(cleanHandle);
  }

  if (key === SocialHandleKey.LINKEDIN) {
    return handle
      .replace(QUERY_PARAMS_PATTERN, "")
      .replace(SOCIAL_URL_PATTERN, "");
  }

  if (key === SocialHandleKey.BLUESKY) {
    return handle
      .replace(QUERY_PARAMS_PATTERN, "")
      .replace(BLUESKY_PROFILE_PATTERN, "");
  }

  return handle
    .replace(QUERY_PARAMS_PATTERN, "")
    .replace(SOCIAL_URL_PATTERN, "")
    .replace(AFTER_FIRST_SLASH_PATTERN, "");
}

export function checkUrlForPatternMatch(key: string, value: string | null) {
  if (value && key === SocialHandleKey.YOUTUBE) {
    return SOCIAL_URL_PATTERN.test(value);
  }
  return true;
}

export function checkYoutubeVideoUrlForPatternMatch(url?: string) {
  if (url) {
    return YOUTUBE_URL_REGEX.test(url);
  }
  return true;
}

export function extractYouTubeVideoId(url?: string | null) {
  const match = url?.match(YOUTUBE_URL_REGEX);
  if (match && match[1]) {
    // Extracted video ID
    return match[1];
  }
  return url || undefined;
}
